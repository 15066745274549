import createAnalyticsString from '@/utils/createAnalyticsString';
import GALLERY from '@/graphql/queries/GALLERY';
import GALLERY_FOLDER from '@/graphql/queries/GALLERY_FOLDER';

/**
 * @function getDataType
 * @description provides data-widget-data-type string for analytics
 * @param {Object} criteria
 * @param {Boolean} isCurated
 * @returns {String} - "manual", "trending", or "automatic"
 */
export const getDataType = (criteria, isCurated) => {
	if (isCurated) return 'manual';

	return criteria?.dataType === 'TRENDING' ? 'trending' : 'automatic';
};

/**
 * @function getWidgetCategory
 * @description provides data-widget-category string for analytics
 * @param {Object} criteria
 * @param {Boolean} isCurated
 * @returns {String} "null", "trending", or "<category.key>-content"
 */
export const getWidgetCategory = (criteria = {}, isCurated) => {
	const { category, dataType, videoFeedId } = criteria;

	return isCurated
		? 'pinned-content'
		: dataType === 'TRENDING'
		? 'trending-content'
		: dataType === 'VIDEO_FEED'
		? createAnalyticsString(`${videoFeedId}-content`)
		: `${category?.key}-content`;
};

/**
 * @function getThemeClassName
 * @description returns the class name for a given widget color theme
 * @param {String} theme
 * @returns {String}
 */
export const getThemeClassName = (theme) => {
	// TO DO: remove light theme when defunct
	return theme === 'DARK' || theme === 'BLACK'
		? 'widget--dark'
		: theme === 'LIGHT'
		? 'widget--light'
		: theme === 'GREY'
		? 'widget--gray'
		: theme === 'WHITE'
		? 'widget--white'
		: '';
};

/**
 * @function getContentListFromGalleryFolders
 * @description returns a processed contentlist for Gallery folders
 * @param {Array} contentList
 * @param {String} widgetType
 * @returns {Array}
 */
export const getContentListFromGalleryFolders = (contentList, widgetType) => {
	/* Recreate the contentList to show the gallery folder thumbnail and title otherwise show the first gallery in the gallery folder
	 * Creates a contentList with one item
	 */
	function getFirstGallery() {
		const gallery = contentList?.[0]?.reference?.galleries?.[0];
		const { displayImage, displayTitle } = !gallery?.thumbnail?.id
			? {
					displayTitle: contentList?.[0]?.displayTitle,
					displayImage: contentList?.[0]?.displayImage
			  }
			: {
					displayTitle: gallery?.title,
					displayImage: {
						id: gallery?.thumbnail?.id,
						title: gallery?.thumbnail?.title,
						uri: gallery?.thumbnail?.uri,
						sourceWidth: gallery?.thumbnail?.sourceWidth,
						sourceHeight: gallery?.thumbnail?.sourceHeight
					}
			  };
		const reference = {
			...gallery
		};

		const linkType = 'GALLERY';
		const updatedContentList = [{ displayImage, displayTitle, reference, linkType }];
		return updatedContentList;
	}

	/* Recreate the contentlist to show all the galleries within all of the gallery folders
	 * Creates a contentList with multiple items
	 */
	function getAllGalleries() {
		const updatedContentList = contentList.flatMap(
			(galleryFolder) =>
				galleryFolder?.reference?.galleries?.map((gallery) => {
					return {
						displayTitle: gallery?.title,
						displayImage: {
							id: gallery?.thumbnail?.id,
							title: gallery?.thumbnail?.title,
							uri: gallery?.thumbnail?.uri,
							sourceWidth: gallery?.thumbnail?.sourceWidth,
							sourceHeight: gallery?.thumbnail?.sourceHeight
						},
						linkType: 'GALLERY',
						reference: {
							id: gallery?.id,
							uri: gallery?.uri,
							...gallery
						}
					};
				}) || []
		);
		return updatedContentList;
	}

	/* GalleryFolders are a linkType where every item in the contentlist will share the same linktype.
	 * If there isn't a GALLERY_FOLDER linktype detected then just return the original contentList.
	 */
	if (!contentList || !contentList.find((content) => content.linkType === 'GALLERY_FOLDER')) {
		return contentList;
	}

	const updatedWidgetType = !widgetType ? '' : widgetType;
	return updatedWidgetType.toUpperCase() === 'HERO' ? getFirstGallery() : getAllGalleries();
};

/**
 * @function prepareAllGalleryTypes
 * @description returns an object of contentList fields as well as params for the request
 * @param {Array} contentList
 * @returns {Object}
 */
export const prepareAllGalleryTypes = (contentList) => {
	/* GalleryFolders are a linkType where every item in the contentlist will share the same linktype.
	 * If there isn't a GALLERY_FOLDER linktype detected then just return the original contentList.
	 */

	if (!contentList) return {};
	else if (contentList.find((content) => content.linkType === 'GALLERY_FOLDER')) {
		return {
			galleryId: contentList[0]?.reference?.folderKey,
			galleryTitle: contentList[0]?.reference?.title,
			nextGalleryInfo: null,
			query: GALLERY_FOLDER,
			queryName: 'galleryFolder',
			variables: {
				folderKey: contentList[0]?.reference?.folderKey
			},
			...contentList[0]
		};
	} else { //Continue supporting original GALLERY functionality
		return {
			galleryId: contentList[0]?.reference?.id,
			galleryTitle: contentList[0]?.reference?.shortTitle || contentList[0]?.displayTitle,
			nextGalleryInfo: Object.assign({}, contentList[0]?.reference?.additionalContentLink),
			query: GALLERY,
			queryName: 'gallery',
			variables: {
				id: contentList[0]?.reference?.id
			},
			...contentList[0]
		};
	}
};

/**
 * @function mapGalleryFolderToGallery
 * @description returns a maps Gallery Folder to the Gallery structure. Original Gallerys are unchanged.
 * @param {String} linkType
 * @param {Object} preGallery
 * @returns {Object}
 */
export const mapGalleryFolderToGallery = (linkType, preGallery) => {
	return linkType === 'GALLERY_FOLDER'
		? {
				additionalContentLink: null,
				authors: [preGallery?.createdBy],
				categories: null,
				galleryitems: preGallery?.galleryItems,
				id: preGallery?.id,
				lastModDate: preGallery?.updatedAt,
				pageMetaData: null,
				publishDate: null,
				subhead: null,
				thumbnail: preGallery?.galleries?.[0]?.thumbnail || preGallery?.thumbnail,
				title: preGallery?.title,
				uri: preGallery?.uri
		  }
		: preGallery;
};
