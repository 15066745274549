import { gql } from '@apollo/client';

export default gql`
	query GALLERY($id: ID!, $skip: Skip, $limit: Limit, $edition: EDITIONKEY!) {
		gallery(id: $id) {
			id
			title
			publishDate
			lastModDate
			subhead
			uri(edition: $edition)
			pageMetaData(edition: $edition) {
				canonicalUrl
				defaultUrl
				redirectUrl
				hreflang {
					edition
					uri
				}
			}
			thumbnail(edition: $edition) {
				uri
			}
			authors {
				fullName
			}
			categories(edition: $edition) {
				name
				displayName(edition: $edition)
			}
			branding {
				brandingType
				displayText
				disclosureText
				ads {
					adKeywords
				}
				adTracking {
					advertiser
				}
			}
			show(edition: $edition) {
				id
				title
				uri(edition: $edition)
				logo(edition: $edition) {
					id
					sourceHeight
					sourceWidth
					title
					uri
				}
				tuneIn
				brandHexColor1
				instagramAccountUrl
				youtubeAccountUrl
				facebookAccountUrl
				twitterAccountUrl
			}
			additionalContentLink(edition: $edition) {
				title
				uri(edition: $edition)
				thumbnail(edition: $edition) {
					uri
					title
					sourceWidth
					sourceHeight
				}
			}
			galleryitems(filter: { skip: $skip, limit: $limit }) {
				nodes {
					image(edition: $edition) {
						uri
						id
						agency
						sourceWidth
						sourceHeight
						title
					}
					title
					caption
				}
				totalCount
			}
		}
	}
`;
