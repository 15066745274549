import { gql } from '@apollo/client';

export default gql`
	query GALLERY_FOLDER($edition: EDITIONKEY!, $folderKey: ID!, $limit: Limit, $skip: Int) {
		galleryFolder(folderKey: $folderKey) {
			folderKey
			title
			updatedAt
			createdAt
			lastUpdatedBy
			createdBy
			id
			uri
			type
			thumbnail(edition: $edition) {
				id
				title
				uri
				sourceWidth
				sourceHeight
				agency
			}
			isLocked
			galleries {
				thumbnail(edition: $edition) {
					id
					title
					uri
					sourceWidth
					sourceHeight
					agency
				}
			}
			galleryItems(filter: { limit: $limit, skip: $skip }) {
				nodes {
					title
					caption
					image(edition: $edition) {
						id
						title
						description
						sourceWidth
						sourceHeight
						uri
						agency
					}
				}
				totalCount
			}
		}
	}
`;
