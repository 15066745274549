import { getCookie, setCookie } from '@/utils/cookies';
import nanoid from 'nanoid';

/**
 * @function buildAdPlayerUrlParams
 * @description builds the FW ad params
 * @param {Object} opts
 * @param {String} [opts.csid]
 * @param {String} [opts.policyid]
 * @param {String} [opts.uid]
 * @param {String} [opts.oneTrustGdprParams]
 * @return {String}
 */
export const buildAdPlayerUrlParams = ({ csid, policyid, uid, oneTrustGdprParams } = {}) => {
	let str = `_fw_h_referer=${window.location.hostname}`;
	if (csid) str += `&csid=${csid}`;
	if (policyid) str += `&policy=${policyid}`;
	if (uid) str += `&vcid2=${uid}`;
	if (oneTrustGdprParams) str += `&${oneTrustGdprParams}`;
	return str;
};

// Convert to string of URL encoded keys / values
export const encodeParams = (params) =>
	encodeURIComponent(
		Object.keys(params)
			.map((key) => `${key}=${params[key]}`)
			.join('&')
	);

/**
 * @function formatDuration
 * @description Convert seconds to MM:SS format
 * @param {number} duration Length of video in seconds
 * @returns {string}
 */
export const formatDuration = (duration) => {
	// Return nothing if duration is not a number
	if (typeof duration !== 'number') return null;

	// Format duration to MM:SS
	const minutes = duration > 0 ? Math.floor(duration / 60) : 0;
	const seconds = duration > 0 ? Math.round(duration % 60) : '00';
	const paddedSeconds = seconds.toString().length === 1 ? '0' + seconds : seconds.toString();
	const formattedTime = `${minutes}:${paddedSeconds}`;

	return formattedTime;
};

/**
 * @function generateCsid
 * @description Generate Freewheel Site Section ID (CSID) based on adEdition, contentEdition, and isMobile()
 * @param {String} adEdition The value of the user's adEdition cookie
 * @returns {String}
 */
export const generateCsid = () => {
	// Get adEdition
	const adEdition = getAdEdition();

	// Get isMobile
	const isMobile = isMobileUserAgent(navigator.userAgent);

	// Base CSID value
	let baseCsid = 'e_online_vod';

	// LATAM, denoted by an 'la' adEdition, uses the geoEdition in the CSID
	if (adEdition === 'la') {
		const geoEdition = getGeoEdition();
		baseCsid += '_' + geoEdition;
	} else {
		// Otherwise set CSID based on adEdition
		baseCsid += '_' + adEdition;
	}

	// Default to US, which uses a legacy CSID in a slightly different format
	if (!adEdition || adEdition === 'us') baseCsid = 'e_online_detail_vod';

	// Set for mobile devices if needed
	if (isMobile) baseCsid += '_tab';

	return baseCsid;
};

/**
 * @function getAdEdition
 * @description Read the value of the adEdition cookie set by Akamai
 * @returns {String}
 */
export const getAdEdition = () => {
	// Get adEdition set from the cookie set by Akamai
	const adEditionCookie = getCookie('adEdition');
	return adEditionCookie !== null ? adEditionCookie.toString().toLowerCase() : 'us';
};

/**
 * @function getFreewheelUserId
 * @description Read the value of the _uid cookie set by Akamai, via the Freewheel cookie sync pixel managed in Adobe Launch
 * @returns {String|null}
 */
export const getFreewheelUserId = () => {
	// Get UID from the cookie set by Akamai, which is populated by the value of a URL parameter.
	// This value is also known as vcid2 (Freewheel) and ssuid (NBCU AdOps)
	// Because this depends on Akamai, it will only work in the Sandbox and Production environments.
	const freewheelUserId = getCookie('_uid');
	if (freewheelUserId) return freewheelUserId;

	// If uid doesn't exist we need to create a fallback
	// Fallback must match /^([a-zA-Z0-9-]){6,6}([a-zA-Z0-9-]){15,15}$/
	const first = nanoid().replace(/[_]/g, '').slice(0, 6);
	const second = nanoid().replace(/[_]/g, '').slice(0, 15);
	const value = `${first}${second}`;

	// Set the fallback cookie for easier debugging & knowing its not from akamai - Default to a year expiration
	setCookie('ssuid', value);
	/* CPC (Cloud Path Client) - Will always use the _uid cookie. If 	any sort of fallback ID is to be made outside of
	 CPC then it has to be put into the _uid cookie when CPC gets that value. [CWA-7387] */
	setCookie('_uid', value);

	return value;
};

/**
 * @function getGeoEdition
 * @description Read the value of the geoEdition cookie set by Akamai
 * @returns {String}
 */
export const getGeoEdition = () => {
	// Get geoEdition set from the cookie set by Akamai
	const geoEditionCookie = getCookie('geoEdition');
	return geoEditionCookie !== null ? geoEditionCookie.toString().toLowerCase() : 'us';
};

/**
 * @function getPolicyByContentEdition
 * @description Look up Freewheel ad policy ID by site content edition
 * @param {String} contentEdition The current site content edition e.g. /de or /uk
 * @returns {String}
 */
export const getPolicyByContentEdition = (contentEdition) => {
	const policyMap = {
		us: '155289502',
		ar: '162969520',
		au: '163481523',
		br: '162969528',
		ca: '162969533',
		co: '162969538',
		de: '162969543',
		fr: '162969551',
		mx: '162969562',
		uk: '163481455',
		ve: '162969567',
		ap: '162969572'
	};

	return policyMap[contentEdition] ? policyMap[contentEdition] : null;
};

/**
 * @function isMobileUserAgent
 * @description Legacy method Freewheel uses to differentiate mobile and non-mobile devices by user agent
 * @param {String} userAgent The value of navigator.userAgent
 * @returns {Boolean}
 */
export const isMobileUserAgent = (userAgent) => {
	// As the user agents being checked indicates, this is a very old approach to mobile device detection.
	// For whatever reason Freewheel still uses this for ad targeting, so here it remains.
	return /android|iphone|ipad|ipod|blackberry|iemobile|opera mini|webos|playbook|silk/i.test(
		userAgent
	);
};
