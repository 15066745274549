import React, { useState, useEffect, createRef } from 'react';
import throttle from 'lodash-es/throttle';
import SvgImage from '@/components/icons/SvgImage';
import chevronThin from '@/images/chevron-thin.svg';
import useThrottledResizeObserver from '@/utils/useThrottledResizeObserver';
import { array, string, number } from 'prop-types';
import { inactiveArrowClass } from './sliderConstants';
import './Slider.scss';

/**
 * @function Slider
 * @description Horizontal element slider with navigating arrows
 * advances 100% component wrapper width at a time
 * @param {Array} children elements to render
 * @param {String} className
 * @param {String} arrowClassName custom class for arrow icons;
 * can make use of built in styles to controll height: .arrow-sm, arrow-lrg
 * @param {Number} [slideWidth] width of each slide item
 * @returns {React.ReactElement}
 */
const Slider = ({ children, className, arrowClassName, slideWidth }) => {
	if (!children) return null;
	const [showPrevBtn, setShowPrevBtn] = useState(false);
	const [showNextBtn, setShowNextBtn] = useState(true);
	// default scrollable width to a full column layout in the smallest desktop view
	const [scrollWidth, setScrollWidth] = useState(729);
	const sliderEl = createRef();
	const { ref, width } = useThrottledResizeObserver(300);

	const updateArrows = () => {
		if (sliderEl.current != null) {
			// current amount that it's scrolled
			const left = sliderEl.current.scrollLeft;
			// how much it's able to scroll over
			const endWidth = sliderEl.current.scrollWidth - sliderEl.current.clientWidth;

			// check if there's a scrollable width
			if (sliderEl.current.scrollWidth > sliderEl.current.clientWidth) {
				// hide previous button if scrolled at the beginning
				left === 0 ? setShowPrevBtn(false) : setShowPrevBtn(true);
				// hide next button if scrolled to the end
				left >= endWidth ? setShowNextBtn(false) : setShowNextBtn(true);
			} else {
				// if the list is too small to scroll, hide buttons
				setShowPrevBtn(false);
				setShowNextBtn(false);
			}
		}
	};

	// sets how far to scroll that also keeps the first visible slide flush left
	const updateScrollWidth = () => {
		if (sliderEl?.current?.clientWidth) {
			// find the width to scroll, based on the amount of full slides that can be moved over at a time
			const width = Math.floor(sliderEl.current.clientWidth / slideWidth) * slideWidth;
			setScrollWidth(width);
		}
	};

	useEffect(() => {
		// check arrow and scrollable width status if window is resized
		updateArrows();
		updateScrollWidth();
	}, [width]);

	useEffect(() => {
		if (sliderEl.current != null) {
			// gets the amount to scroll
			updateScrollWidth();
			// check button status on scroll event
			// covers updates for user clicks and touch / mouse scrolling
			const scrollListener = throttle(updateArrows, 100);
			sliderEl.current.addEventListener('scroll', scrollListener);
			return () => {
				if (sliderEl.current != null) {
					sliderEl.current.removeEventListener('scroll');
				}
			};
		}
	}, [sliderEl]);

	// scroll left on previous click
	const prevClick = () => {
		// adjust scroll amount if can't scroll back a whole width
		const remainder = sliderEl.current.scrollLeft % scrollWidth;
		const scrollAmt = remainder > 0 ? remainder : scrollWidth;
		sliderEl.current.scrollLeft = sliderEl.current.scrollLeft - scrollAmt;
	};

	// scroll right on next click
	const nextClick = () => {
		sliderEl.current.scrollLeft = sliderEl.current.scrollLeft + scrollWidth;
	};

	return children.length ? (
		<div data-hook="slider" className={`slider__wrapper ${className}`} ref={ref}>
			<div className="slider" ref={sliderEl}>
				{children}
			</div>
			<button
				data-hook="slider-button-prev"
				className={`slider__arrows slider__arrows--prev ${
					showPrevBtn ? '' : inactiveArrowClass
				} ${arrowClassName}`}
				onClick={prevClick}
			>
				<SvgImage image={chevronThin} className="slider__arrow" />
			</button>
			<button
				data-hook="slider-button-next"
				className={`slider__arrows slider__arrows--next ${
					showNextBtn ? '' : inactiveArrowClass
				} ${arrowClassName}`}
				onClick={nextClick}
			>
				<SvgImage image={chevronThin} className="slider__arrow" />
			</button>
		</div>
	) : null;
};

Slider.defaultProps = {
	className: '',
	arrowClassName: 'arrow-lrg',
	slideWidth: 300
};

Slider.propTypes = {
	children: array.isRequired,
	className: string,
	arrowClassName: string,
	slideWidth: number
};

Slider.displayName = 'Slider';

export default Slider;
